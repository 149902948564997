<template>
    <b-container fluid>
        <b-row>
            <b-col>
                <h1>MPC Obrasci</h1>
            </b-col>
            <b-col cols="6" class="print-show">
                <mara-table :item="selectedClient"></mara-table>
            </b-col>
        </b-row>
        <b-row class="my-2">
            <b-col>
                <router-link to="/mpc_forms/create">
                    <b-btn variant="primary">Novi MPC obrazac</b-btn>
                </router-link>
            </b-col>
        </b-row>
        <b-row v-if="mpcForms">
            <b-col>
                <osta-table position
                            :items="mpcForms"
                            :fields="mpcFormsFields"
                            @rowClicked="goToMpcForm">
                </osta-table>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
    export default {
        name: 'ClientsMpcForms',
        data() {
            return {
                mpcForms: null,
                mpcFormsFields: [
                    {
                        key: 'associate.name',
                        label: 'Dobavljac',
                    },
                    {
                        key: 'invoice_number',
                        label: 'Broj racuna',
                        sortable: true
                    },
                    {
                        key: 'invoice_date',
                        label: 'Datum računa',
                        formatter: (value) => this.$moment(value).format("DD.MM.YYYY.")
                    },
                    {
                        key: 'form_number',
                        label: 'Broj obrasca',
                    },
                    {
                        key: 'form_date',
                        label: 'Datum obrasca',
                        sortable: true,
                        formatter: (value) => this.$moment(value).format("DD.MM.YYYY.")
                    },
                    {
                        key: 'confirmed',
                        label: 'Potvrđena',
                        formatter: (value) => (value === 0) ? 'Ne' : 'Da'
                    }
                ]
            }
        },
        computed: {
            selectedClient: function () {
                return this.$store.getters.selectedClient;
            }
        },
        methods: {
            goToMpcForm(id) {
                this.$router.push('/mpc_forms/' + id);
            },
            getMpcForms() {
                axiOsta.post(api.clients + 'mpc_forms', {
                    client_id: this.$parent.selectedClient.id,
                    year: this.$cookie.get('ezint-year')
                }).then(response => {
                    if (response.status === 200) {
                        this.mpcForms = response.data;
                    }
                })
            }
        },
        created() {
            if (this.selectedClient != null) {
                this.getMpcForms();
            }
            eventBus.$on('year-changed', () => {
                this.getMpcForms();
            });
        },
        watch: {
            selectedClient: function () {
                this.getMpcForms();
            }
        }
    }
</script>
